import { Link } from 'react-router-dom';
import React from 'react';
import "./ProjectsPageStyles.css"
import { useState } from 'react';

const ProjectsPage = () => {

  const [cards] = useState([
    {
      title: 'AlphaInsights',
      text: 'Research on alpha-synuclein.',
      link: 'http://www.alphainsights.ch',
      isExternal: true,
    },
    {
      title: 'Gallery',
      text: 'I like taking pictures.',
      link: '/projects/gallery',
    },
    {
      title: 'Game',
      text: 'A hangman game against boredom.',
      link: '/projects/game'
    },
    {
      title: 'Educational Resources',
      text: 'Summaries, etc.',
      link: '/projects/resources'
    },
  ]);

  return (
    <div className="projectsland">
      <div className="projectsmask">
        <div className="content">
          <h1>Projects</h1>
          <h2>see below for some of my recent projects:</h2>
          <div className='cards'>
            {
              cards.map((card, i) => (
                <div key={i} className='card'>
                  <h3> {card.title} </h3>
                  <p> {card.text} </p>
                  <button className='prjtbtn'><Link to={card.link}>Explore</Link></button>
                </div>
              ))
            }
            
          </div>  
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
