import React from 'react';
import Navbar from '../components/Navbar.jsx';
import LandingPage from '../components/LandingPage.jsx';
import Footer from '../components/Footer.jsx';

const Home = () => {
  return (
    <div>
      <Navbar />
      <LandingPage />
      <Footer />
    </div>
  )

}

export default Home;
