import React from 'react'
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import ProjectsPage from '../components/ProjectsPage.jsx';

const Projects = () => {
  return (
    <div>
      <Navbar />
      <ProjectsPage />
      <Footer />
    </div>
  )
}

export default Projects
