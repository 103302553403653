import React from 'react';
import "./AboutPageStyles.css";
import { MdOutlineHexagon } from "react-icons/md";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <div className="aboutland">
         <div className="aboutmask">
            <div className="content">
            <Link to="/about/hannahs"><MdOutlineHexagon className="hexagon" size={30}/></Link>
            <h1>About me</h1>
            <h3>I'm Isabella, an interdisciplinary science student at <b>ETH Zurich</b>. <b>Biochemistry</b>, <b>engineering</b>, and <b>computer science</b> are just a few interests of mine.</h3>
            <h3>Beyond the lab and education, I like to get <b>creative</b> and be <b>active</b>.</h3>
            <h3>Life's complexity fascinates me, and I'm on a mission to bridge the gaps between disciplines to make people happy, do good for the world and feed my hunger for knowledge.</h3>
            </div>   
         </div>
    </div>
  )
}

export default AboutPage

