import React from 'react'
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import ImageGallery from '../components/ImageGallery.jsx';

const Gallery = () => {
  return (
    <div>
      <Navbar />
      <ImageGallery />
      <Footer />
    </div>
  )
}

export default Gallery
