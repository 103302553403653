import React from 'react';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import HangmanGamePage from '../components/HangmanGamePage.jsx';

const HangmanGame = () => {
  return (
    <div>
      <Navbar />
      <HangmanGamePage />
      <Footer />
    </div>
  )
}

export default HangmanGame