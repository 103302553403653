import "./FooterStyles.css";
import { HiOutlineMail } from "react-icons/hi";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        
        <div className="socials">
            <a href="mailto:mail@ifmv.org"><HiOutlineMail size={30} style={{ color:"rgba(255,255,255,0.8)", marginRight: "1rem" }}/></a>
            <a href="https://www.linkedin.com/in/isabellamueller-vogt"><FaLinkedin size={30} style={{ color:"rgba(255,255,255,0.8)", marginRight: "1rem" }}/></a>
            <a href="https://github.com/isabellafmv"><FaGithub size={30} style={{ color:"rgba(255,255,255,0.8)", marginRight: "1rem" }}/></a>
        </div>
        <p>&copy;Isabella Mueller-Vogt 2024</p>

      </div>  
    </div>
  )
}

export default Footer
