import React from 'react';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import HannahsPage from '../components/HannahsPage.jsx';

const Hannahs = () => {
  return (
    <div>
      <Navbar />
      <HannahsPage />
      <Footer />
    </div>
  )
}

export default Hannahs
