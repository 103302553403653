import React from 'react';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx';
import ContactPage from '../components/ContactPage.jsx';

const Contact = () => {
  return (
    <div>
      <Navbar />
      <ContactPage />
      <Footer />
    </div>
  )
}

export default Contact;
