import React from 'react';
import "./HannahsPageStyles.css";
import { useState } from 'react';
import haareschneiden from "../images/haareschneiden.png";
import hannah_illuminarium from "../images/hannah_illuminarium.png";
import hannah_polyball from "../images/hannah_polyball.png";
import hannah_zimtschnecken from "../images/hannah_zimtschnecken.png";
import hannahs_polyball from "../images/hannahs_polyball.png";
import hannahs_redbull from "../images/hannahs_redbull.png";
import hanzel_mariusgeb from "../images/hanzel_mariusgeb.png";
import hanzel_schneetag from "../images/hanzel_schneetag.png";
import hinki_nikshuette from "../images/hinki_nikshuette.png";

const HannahsPage = () => {

    const [cards] = useState([
        {
            image: haareschneiden,
        },
        {
            image: hannah_illuminarium,
        },
        {
            image: hannah_polyball,
        },
        {
            image: hannah_zimtschnecken
        },
        {
            image: hannahs_polyball,
        },
        {
            image: hannahs_redbull,
        },
        {
            image: hanzel_mariusgeb,
        },
        {
            image: hanzel_schneetag,
        },
        {
            image: hinki_nikshuette,
        },
      ]);

  return (
    <div className='hannahland'>
       <div className="hannahmask">
            <div className="content">
                <h1>Tribute to my Hannah's</h1>
                <h3>Die Mäuse sind toll!</h3>
                <div className='hannahcards'>
                    {
                    cards.map((card, i) => (
                        <div key={i} className='hannahcard'>
                        <img src={card.image} alt={`Card ${i + 1}`} />
                        <p> {card.text} </p>
                        </div>
                    ))
                    }
                </div>
            </div>   
         </div>
    </div>
  )
}

export default HannahsPage
