import React, { useState } from 'react';
import "./ProjectsPageStyles.css";
import "./HangmanGamePageStyles.css";

// A static list of words to replace reading from a CSV file
const WORDS = [
  'absolute', 'abstract', 'academic', 'accepted', 'accident', 'accuracy', 'accurate', 'achieved', 'acquired', 'activity',
  'actually', 'addition', 'adequate', 'adjacent', 'adjusted', 'advanced', 'advisory', 'advocate', 'affected', 'aircraft',
  'alliance', 'although', 'aluminum', 'analysis', 'announce', 'anything', 'anywhere', 'apparent', 'appendix', 'approach',
  'approval', 'argument', 'artistic', 'assembly', 'assuming', 'athletic', 'attached', 'attitude', 'attorney', 'audience',
  'autonomy', 'aviation', 'bachelor', 'bacteria', 'baseball', 'bathroom', 'becoming', 'benjamin', 'birthday', 'boundary',
  'breaking', 'breeding', 'building', 'bulletin', 'business', 'calendar', 'campaign', 'capacity', 'casualty', 'catching',
  'category', 'Catholic', 'cautious', 'cellular', 'ceremony', 'chairman', 'champion', 'chemical', 'children', 'circular',
  'civilian', 'clearing', 'clinical', 'clothing', 'collapse', 'colonial', 'colorful', 'commence', 'commerce', 'complain',
  'complete', 'composed', 'compound', 'comprise', 'computer', 'conclude', 'concrete', 'conflict', 'confused', 'congress',
  'consider', 'constant', 'consumer', 'continue', 'contract', 'contrary', 'contrast', 'convince', 'corridor', 'coverage',
  'covering', 'creation', 'creative', 'criminal', 'critical', 'crossing', 'cultural', 'currency', 'customer', 'database',
  'daughter', 'daylight', 'deadline', 'deciding', 'decision', 'decrease', 'deferred', 'definite', 'delicate', 'delivery',
  'describe', 'designer', 'detailed', 'diabetes', 'dialogue', 'diameter', 'directly', 'director', 'disabled', 'disaster',
  'disclose', 'discount', 'discover', 'disorder', 'disposal', 'distance', 'distinct', 'district', 'dividend', 'division',
  'doctrine', 'document', 'domestic', 'dominant', 'dominate', 'doubtful', 'dramatic', 'dressing', 'dropping', 'duration',
  'dynamics', 'earnings', 'economic', 'educated', 'efficacy', 'eighteen', 'election', 'electric', 'eligible', 'emerging',
  'emphasis', 'employee', 'endeavor', 'engaging', 'engineer', 'enormous', 'entirely', 'entrance', 'envelope', 'equality',
  'equation', 'estimate', 'evaluate', 'eventual', 'everyday', 'everyone', 'evidence', 'exchange', 'exciting', 'exercise',
  'explicit', 'exposure', 'extended', 'external', 'facility', 'familiar', 'featured', 'feedback', 'festival', 'finished',
  'firewall', 'flagship', 'flexible', 'floating', 'football', 'foothill', 'forecast', 'foremost', 'formerly', 'fourteen',
  'fraction', 'franklin', 'frequent', 'friendly', 'frontier', 'function', 'generate', 'generous', 'genomics', 'goodwill',
  'governor', 'graduate', 'graphics', 'grateful', 'guardian', 'guidance', 'handling', 'hardware', 'heritage', 'highland',
  'historic', 'homeless', 'homepage', 'hospital', 'humanity', 'identify', 'identity', 'ideology', 'imperial', 'incident',
  'included', 'increase', 'indicate', 'indirect', 'industry', 'informal', 'informed', 'inherent', 'initiate', 'innocent',
  'inspired', 'instance', 'integral', 'intended', 'interact', 'interest', 'interior', 'internal', 'interval', 'intimate',
  'intranet', 'invasion', 'involved', 'isolated', 'judgment', 'judicial', 'junction', 'keyboard', 'landlord', 'language',
  'laughter', 'learning', 'leverage', 'lifetime', 'lighting', 'likewise', 'limiting', 'literary', 'location', 'magazine',
  'magnetic', 'maintain', 'majority', 'marginal', 'marriage', 'material', 'maturity', 'maximize', 'meantime', 'measured',
  'medicine', 'medieval', 'memorial', 'merchant', 'midnight', 'military', 'minimize', 'minister', 'ministry', 'minority',
  'mobility', 'modeling', 'moderate', 'momentum', 'monetary', 'moreover', 'mortgage', 'mountain', 'mounting', 'movement',
  'multiple', 'national', 'negative', 'nineteen', 'northern', 'notebook', 'numerous', 'observer', 'occasion', 'offering',
  'official', 'offshore', 'operator', 'opponent', 'opposite', 'optimism', 'optional', 'ordinary', 'organize', 'original',
  'overcome', 'overhead', 'overseas', 'overview', 'painting', 'parallel', 'parental', 'patented', 'patience', 'peaceful',
  'periodic', 'personal', 'persuade', 'petition', 'physical', 'pipeline', 'platform', 'pleasant', 'pleasure', 'politics',
  'portable', 'portrait', 'position', 'positive', 'possible', 'powerful', 'practice', 'precious', 'pregnant', 'presence',
  'preserve', 'pressing', 'pressure', 'previous', 'princess', 'printing', 'priority', 'probable', 'probably', 'producer',
  'profound', 'progress', 'property', 'proposal', 'prospect', 'protocol', 'provided', 'provider', 'province', 'publicly',
  'purchase', 'pursuant', 'quantity', 'question', 'rational', 'reaction', 'received', 'receiver', 'recovery', 'regional',
  'register', 'relation', 'relative', 'relevant', 'reliable', 'reliance', 'religion', 'remember', 'renowned', 'repeated',
  'reporter', 'republic', 'required', 'research', 'reserved', 'resident', 'resigned', 'resource', 'response', 'restrict',
  'revision', 'rigorous', 'romantic', 'sampling', 'scenario', 'schedule', 'scrutiny', 'seasonal', 'secondly', 'security',
  'sensible', 'sentence', 'separate', 'sequence', 'sergeant', 'shipping', 'shortage', 'shoulder', 'simplify', 'situated',
  'slightly', 'software', 'solution', 'somebody', 'somewhat', 'southern', 'speaking', 'specific', 'spectrum', 'sporting',
  'standard', 'standing', 'standout', 'sterling', 'straight', 'strategy', 'strength', 'striking', 'struggle', 'stunning',
  'suburban', 'suitable', 'superior', 'supposed', 'surgical', 'surprise', 'survival', 'sweeping', 'swimming', 'symbolic',
  'sympathy', 'syndrome', 'tactical', 'tailored', 'takeover', 'tangible', 'taxation', 'taxpayer', 'teaching', 'tendency',
  'terminal', 'terrible', 'thinking', 'thirteen', 'thorough', 'thousand', 'together', 'tomorrow', 'touching', 'tracking',
  'training', 'transfer', 'traveled', 'treasury', 'triangle', 'tropical', 'turnover', 'ultimate', 'umbrella', 'universe',
  'unlawful', 'unlikely', 'valuable', 'variable', 'vertical', 'victoria', 'violence', 'volatile', 'warranty', 'weakness',
  'weighted', 'whatever', 'whenever', 'wherever', 'wildlife', 'wireless', 'withdraw', 'woodland', 'workshop', 'yourself'
];


function HangmanGamePage() {
  const [chosenWord] = useState(WORDS[Math.floor(Math.random() * WORDS.length)]);
  const [guessWord, setGuessWord] = useState(Array(chosenWord.length).fill('_'));
  const [inputs, setInputs] = useState([]);
  const [number_of_guesses, setNumberOfGuesses] = useState(0);

  const handleGuess = (e) => {
    e.preventDefault();
    const guess = e.target.elements.letter.value.trim().toLowerCase();
    e.target.elements.letter.value = ''; // Reset input field
  
    if (!guess || inputs.includes(guess) || !guess.match(/^[a-z]$/)) {
      alert("Invalid guess or you've already guessed that letter.");
      return;
    }
  
    let newInputs = [...inputs, guess];
    setInputs(newInputs);
    setNumberOfGuesses(prev => prev + 1); // Update using previous state
  
    // Moved the declaration of newGuessWord to a broader scope within handleGuess
    let newGuessWord = guessWord.map((letter, index) => chosenWord[index] === guess ? guess : letter);
  
    if (chosenWord.includes(guess)) {
      setGuessWord(newGuessWord);
    } else {
      alert("Letter not found.");
    }
  
    // Check if the word is completely guessed
    if (!newGuessWord.includes('_')) {
      alert(`Congrats! You guessed the word: ${chosenWord}. It took you ${number_of_guesses} guesses.`);
    }
  };
  

  return (
    <div className='hmland'>
    <div className='hmmask'>
      <div className='hmcontent'>
      <h1>Hangman Game</h1>
      <p>{guessWord.join(' ')}</p>
      <form onSubmit={handleGuess}>
        <input type="text" name="letter" maxLength="1" />
        <button type="submit" className='prjtbtn'>Guess</button>
      </form>
      <p>Guesses: {inputs.join(', ')}</p>
      </div>
    </div>
    </div>
  );
}

export default HangmanGamePage;


