import React from "react";
import "./index.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import { Route, Routes } from "react-router-dom";
// import ImageGallery from "./components/ImageGallery";
import Gallery from "./pages/Gallery";
import Hannahs from "./pages/Hannahs";
import HangmanGame from "./pages/HangmanGame";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  return (
    <>
      <SpeedInsights />    
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/about/hannahs" element={<Hannahs />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/projects" element={<Projects />}/>
        <Route path="/projects/gallery" element={<Gallery />}/>
        <Route path="/projects/game" element={<HangmanGame />}/>
      </Routes>
    </>
  );
}

export default App;
