import React, {useState, useEffect} from 'react';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import "./ImageGalleryStyles.css";
import { FaTimes, FaArrowRight, FaArrowLeft} from "react-icons/fa";
// import { GoArrowLeft , GoArrowRight } from "react-icons/go";
import IMG_1039 from "../images/IMG_1039.jpg";
import IMG_1409 from "../images/IMG_1409.jpg";
import IMG_1557 from "../images/IMG_1557.jpg";
import IMG_23492 from "../images/intro-img2.jpg";
import IMG_2350 from "../images/IMG_2350.jpg";
import IMG_2968 from "../images/IMG_2968.jpg";
import IMG_3215 from "../images/IMG_3215.jpg";
import IMG_3678 from "../images/IMG_3678.jpg";
import IMG_3831 from "../images/IMG_3831.jpg";
import IMG_4336 from "../images/IMG_4336 2.jpg";
import IMG_4439 from "../images/IMG_4439.jpg";
import IMG_4598 from "../images/IMG_4598.jpg";
import IMG_4678 from "../images/IMG_4678.jpg";
import IMG_4687 from "../images/IMG_4687.jpg";
import IMG_4743 from "../images/IMG_4743.jpg";
import IMG_5053 from "../images/IMG_5053.jpg";
import IMG_5317 from "../images/IMG_5317.jpg";
import IMG_5334 from "../images/IMG_5334.jpg";
import IMG_5345 from "../images/IMG_5345.jpg";
import IMG_5756 from "../images/IMG_5756.jpg";
import IMG_6085 from "../images/IMG_6085.jpg";
import IMG_6675 from "../images/IMG_6675.jpg";
import IMG_6811 from "../images/IMG_6811.jpg";
import IMG_6962 from "../images/IMG_6962.jpg";
import IMG_7441 from "../images/IMG_7441.jpg";
import IMG_7869 from "../images/IMG_7869.jpg";
import IMG_8010 from "../images/IMG_8010.jpg";
import IMG_8041 from "../images/IMG_8041.jpg";
import IMG_8490 from "../images/IMG_8490.jpg";
import IMG_8450 from "../images/IMG_8450.jpg";
import IMG_9681 from "../images/IMG_9681.jpg";
import IMG_8820 from "../images/IMG_8820.jpg";
import IMG_9306 from "../images/IMG_9306.jpg";

const images = [
    IMG_1039,
    IMG_1409,
    IMG_1557,
    IMG_2350,
    IMG_2968,
    IMG_7441,
    IMG_3678,
    IMG_3831,
    IMG_4439,
    IMG_4598,
    IMG_4678,
    IMG_4687,
    IMG_6085,
    IMG_8041,
    IMG_4743,
    IMG_5053,
    IMG_5317,
    IMG_5334,
    IMG_4336,
    IMG_5345,
    IMG_3215,
    IMG_6675,
    IMG_23492,
    IMG_6811,
    IMG_9681,
    IMG_5756,
    IMG_6962,
    IMG_7869,
    IMG_8010,
    IMG_8490,
    IMG_8450,
    IMG_9306,
    IMG_8820
]

const ImageGallery = () => {
    const [data, setData] = useState({img: '', i: 0})

    const viewImage = (img, i) => {
        setData({img, i})
    }
    const showNextImage = () => {
        let i = data.i;
        if (i + 1 < images.length) { // Ensure we do not go out of bounds
            setData({img: images[i + 1], i: i + 1});
        }
    };

    const showPreviousImage = () => {
        let i = data.i;
        if (i - 1 >= 0) { // Ensure we do not go out of bounds
            setData({img: images[i - 1], i: i - 1});
        }
    };
    
    const hideImage = () => {
        setData({img: '', i: 0});
    };
    
    const handleKeyDownLeft = (event) => {
        if (event.key === 'ArrowLeft') {
            showPreviousImage();
        }
    }
    
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDownLeft);

        return () => {
            window.removeEventListener('keydown', handleKeyDownLeft);
        };
    }, [data.i]);

    const handleKeyDownRight = (event) => {
        if (event.key === 'ArrowRight') {
            showNextImage();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDownRight);

        return () => {
            window.removeEventListener('keydown', handleKeyDownRight);
        };
    }, [data.i]);

  return (
    <>
        {data.img &&
            <div className='viewimg'>
                <FaTimes onClick={hideImage} style={{position: 'absolute', bottom: '10px', left: '10px', color: '#fff'}}/>
                <FaArrowLeft onClick={showPreviousImage} style={{color: '#fff', margin:"0 auto 0 5vw"}}/>
                <img src={data.img} className='img' alt=""/>
                <FaArrowRight onClick={showNextImage} style={{color: '#fff', margin: '0 5vw 0 auto'}}/>
            </div>
        }
      <div className="grid">
      <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
            <Masonry gutter='20px'>
                {images.map((image, i) => (
                    <img
                        key={i}
                        src={image}
                        style={{width: "100%", display: "block", cursor: 'pointer', objectFit: "contain"}}
                        alt=""
                        onClick={()=>viewImage(image, i)}
                    />
                ))}
            </Masonry>
        </ResponsiveMasonry> 

      </div>
           
    </>               
  )
}

export default ImageGallery

